import axios from '@/axios'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    pricingTypeList: []
  },
  mutations: {
    SET_PRICINGTYPELIST (state, pricingTypeList) {
      state.pricingTypeList = pricingTypeList
    }
  },
  actions: {
    fetchPricingTypes ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/pricing_types?pagination=false')
          .then((response) => {
            commit('SET_PRICINGTYPELIST', response.data['hydra:member'])
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetch (vuex, resource) {
      return axios.get(resource)
    }
  },
  getters: {
    chargePricingTypeList (state) {
      return state.pricingTypeList.filter(pricingType => pricingType.isCharge)
    },
    chargePricingTypeCount (state, getters) {
      return getters.chargePricingTypeList.length
    },
    pricingOnlyTypeList (state) {
      return state.pricingTypeList.filter(pricingType => !pricingType.isCharge)
    },
    pricingOnlyTypeCount (state, getters) {
      return getters.pricingOnlyTypeList.length
    }
  }
}
