<template>
  <vs-select class="ml-auto my-2 cursor-pointer w-full" v-bind="{...$props, ...$attrs}" v-on="$listeners">
    <vs-select-item v-if="showAll" key=" " value="" :text="$t('pricing.pricing_type.list.all')" />
    <div :key="item.title" v-for="item in pricingTypeList">
      <vs-select-group :title="item.title" v-if="item.group">
        <vs-select-item v-for="item in item.group" :value="`/pricing_types/${item.id}`"
                        :text="$t(`pricing.pricing_type.list.${item.name}`)" :key="item.id" />
      </vs-select-group>
    </div>
  </vs-select>
</template>

<script>
import modulePricingTypeMixin from '@/store/pricing/modulePricingTypeMixin'

export default {
  name: 'PricingTypeSelect',
  mixins: [modulePricingTypeMixin],
  props: {
    showAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pricingTypeList () {
      const pricingList = []

      if (this.$store.getters['pricingType/pricingOnlyTypeCount']) {
        pricingList.push({
          title: this.$t('pricing.pricing_type.group.tariff'),
          group: this.$store.getters['pricingType/pricingOnlyTypeList']
        })
      }

      if (this.$store.getters['pricingType/chargePricingTypeCount']) {
        pricingList.push({
          title: this.$t('pricing.pricing_type.group.charge'),
          group: this.$store.getters['pricingType/chargePricingTypeList']
        })
      }

      return pricingList
    }
  },
  mounted () {
    this.$store.dispatch('pricingType/fetchPricingTypes')
  }
}
</script>

<style scoped>

</style>
