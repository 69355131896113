import moduleComment from './moduleComment'

export default {
  created () {
    if (!moduleComment.isRegistered) {
      this.$store.registerModule('comment', moduleComment)
      moduleComment.isRegistered = true
    }
  }
}
