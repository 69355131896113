<template>
  <div id="pricing-edit" :key="$route.fullPath">
    <vs-tabs v-model="tabsPosition" color="rgba(var(--vs-primary),1)">
      <vs-tab class="2xl:flex" :label="$t('pricing.edit.tab.pricing')" :disabled="$acl.not.check('pricing')">
        <vs-alert color="danger" :title="$t('pricing.edit.pricing_not_found.title')" :active.sync="isPricingNotFound">
          <span>{{ $t('pricing.edit.pricing_not_found.message', { 'userId': $route.params.pricingId }) }}</span>
            <span v-if="$acl.check('admin')">
              <span>
                {{ $t('pricing.edit.pricing_not_found.verify') }}
                <router-link :to="{name:'pricing-list'}" class="text-inherit underline">
                  {{ $t('pricing.edit.pricing_not_found.all_pricing') }}
                </router-link>
              </span>
            </span>
        </vs-alert>
        <template v-if="pricing">
          <vx-card>
            <fix-form v-if="pricing.type.name === 'fix-naturstroum-and-naturgas'" :pricing="pricing" @update:pricing="updatePricing" @post:pricing="postPricing" />
            <electricity-charge-form v-if="pricing.type.name === 'electricity-charge'" :pricing="pricing" @update:pricing="updatePricing" @post:pricing="postPricing" />
            <gas-charge-form v-if="pricing.type.name === 'gas-charge'" :pricing="pricing" @update:pricing="updatePricing" @post:pricing="postPricing" />
            <electricity-standard-form v-if="pricing.type.name === 'electricity-standard'" :pricing="pricing" @update:pricing="updatePricing" @post:pricing="postPricing" />
            <gas-standard-form v-if="pricing.type.name === 'gas-standard'" :pricing="pricing" @update:pricing="updatePricing" @post:pricing="postPricing" />
          </vx-card>
          <vx-card class="comment mt-5 2xl:mt-0 2xl:mx-5 2xl:px-5">
            <comments-view :object="pricing" resource="pricings" />
          </vx-card>
        </template>
      </vs-tab>

      <vs-tab :label="$t('pricing.edit.tab.sheet')" :disabled="isSheetTabDisabled">
        <vx-card>
          <SheetView :pricing="pricing" @update:pricing="updatePricing" @fetch:pricing="fetchPricing" @post:pricing="postPricing" />
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import FixForm from './form/FIXForm'
import ElectricityChargeForm from './form/ElectricityChargeForm'
import GasChargeForm from './form/GasChargeForm'
import ElectricityStandardForm from './form/ElectricityStandardForm'
import GasStandardForm from './form/GasStandardForm'
import CommentsView from '@/components/comments/CommentsView'

import SheetView from './form/SheetView'

// Store Module
import modulePricingMixin from '@/store/pricing/modulePricingMixin'

export default {
  components: {
    FixForm,
    ElectricityChargeForm,
    GasChargeForm,
    ElectricityStandardForm,
    GasStandardForm,
    SheetView,
    CommentsView
  },
  mixins: [modulePricingMixin],
  data () {
    return {
      pricing: null,
      isPricingNotFound: false,
      tabsPosition: 1
    }
  },
  computed: {
    isSheetTabDisabled () {
      return typeof this.pricing === 'undefined' || this.pricing === null || !(this.$acl.check('sheet') && (this.pricing.state === 'published' || this.pricing.state === 'assembled'))
    }
  },
  methods: {
    fetchPricing (pricingId) {
      this.$store.dispatch('pricing/fetchPricing', pricingId)
        .then(res => {
          this.pricing = res.data
          if (this.pricing.state === 'assembled' || this.pricing.state === 'published') {
            this.tabsPosition = 2
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.isPricingNotFound = true
            return
          }
          console.error(err) // eslint-disable-line no-console
        })
    },
    updatePricing (pricing) {
      this.$store.dispatch('pricing/updatePricing', pricing)
        .then(() => {
          this.$router.push({'name': 'pricing-list'})
          this.$vs.notify({
            color: 'success',
            title: this.$t(`pricing.form.${pricing.action}.success.notify.title`),
            text: this.$t(`pricing.form.${pricing.action}.success.notify.text`, { pricingName: pricing.name })
          })
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t(`pricing.form.${pricing.action}.error.notify.title`),
            text: this.$t(`pricing.form.${pricing.action}.error.notify.text`, { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    },
    async postPricing (pricing) {
      try {
        const { data } = await this.$store.dispatch('pricing/addPricing', pricing)
        await this.$router.push({ name: 'pricing-edit', params: { pricingId: data.id }})
        Object.assign(this.pricing, data)
        this.$vs.notify({
          color: 'success',
          title: this.$t('pricing.form.new_pricing_version.success.notify.title'),
          text: this.$t('pricing.form.new_pricing_version.success.notify.text', { pricingName: data.name })
        })
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
        this.$vs.notify({
          color: 'danger',
          title: this.$t('pricing.form.new_pricing_version.error.notify.title'),
          text: this.$t('pricing.form.new_pricing_version.error.notify.text', { error: error.response.data['hydra:description'] }),
          fixed: true
        })
      }
    }
  },
  created () {
    this.fetchPricing(this.$route.params.pricingId)
  }
}
</script>

<style scoped lang="scss">
.comment {
  @media (min-width: 1800px){
    flex-basis: 30%;
  }
}
</style>
