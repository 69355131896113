import Vue from 'vue'
import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    pricings: [],
    totalItems: 0,
    electricityChargeList: [],
    gasChargeList: [],
    sheets: {},
    sheetsTranslations: [],
    sheetsWithVariant: [],
    sheetLastVersion: 1,
    sheetsTotal: 0,
    sheetsCountByAction: {}
  },
  mutations: {
    SET_PRICINGS (state, pricingsData) {
      state.pricings = pricingsData['hydra:member']
      state.totalItems = pricingsData['hydra:totalItems']
    },
    ADD_PRICING (state, pricing) {
      state.pricings.push(pricing)
      state.totalItems++
    },
    REMOVE_PRICING (state, pricingId) {
      const pricingIndex = state.pricings.findIndex(g => g.id === pricingId)
      state.pricings.splice(pricingIndex, 1)
      state.totalItems--
    },
    UPDATE_PRICING (state, pricing) {
      const pricingIndex = state.pricings.findIndex(p => p.id === pricing.id)
      state.pricings[pricingIndex] = pricing
    },
    SET_ELECTRICITYCHARGELIST (state, electricityChargeList) {
      state.electricityChargeList = electricityChargeList
    },
    SET_GASCHARGELIST (state, gasChargeList) {
      state.gasChargeList = gasChargeList
    },
    SET_SHEETS_WITH_VARIANT (state, sheetsVariant) {
      const sheetsWithVariant = sheetsVariant.filter(sheet => sheet.variant).sort((a, b) => {
        if (a.variant.includes('fix')) {
          return a.variant.localeCompare(b.variant, undefined, {
            numeric: true,
            sensitivity: 'base'
          })
        }
        return a.variant.localeCompare(b.variant)
      })
      state.sheetsWithVariant = sheetsWithVariant
    },
    SET_SHEETS (state, sheets) {
      const currentSheet = {}

      for (let key = 0; key < sheets.length; key++) {
        const sheet = sheets[key]

        if (typeof currentSheet[sheet.type.name] === 'undefined') {
          currentSheet[sheet.type.name] = {}
        }

        if (
          typeof currentSheet[sheet.type.name][sheet.company] === 'undefined'
        ) {
          currentSheet[sheet.type.name][sheet.company] = {}
        }

        if (
          typeof currentSheet[sheet.type.name][sheet.company][sheet.locale] === 'undefined'
        ) {
          currentSheet[sheet.type.name][sheet.company][sheet.locale] = {}
        }

        state.sheetsTotal++
        currentSheet[sheet.type.name][sheet.company][sheet.locale][sheet.version] = sheet

        if (typeof state.sheets[sheet.pricing] === 'undefined') {
          state.sheets[sheet.pricing] = currentSheet
        } else {
          Vue.set(state.sheets, sheet.pricing, currentSheet)
        }

        for (let i = 0; i < sheet.action.length; i++) {
          if (
            typeof state.sheetsCountByAction[sheet.action[i]] === 'undefined'
          ) {
            state.sheetsCountByAction[sheet.action[i]] = 0
          }

          state.sheetsCountByAction[sheet.action[i]]++
        }
      }
    },
    ADD_SHEET (state, sheet) {
      state.sheets[sheet.pricing][sheet.type.name][sheet.company][sheet.locale][sheet.version] = sheet
      state.sheetsTotal++
    },
    UPDATE_SHEET (state, sheet) {
      state.sheets[sheet.pricing][sheet.type.name][sheet.company][sheet.locale][sheet.version] = sheet
      if (sheet.state ===  'pending_for_assembly') {
        Vue.set(state.sheets[sheet.pricing][sheet.type.name][sheet.company][sheet.locale], '', sheet)
      }
    },
    SET_TRANSLATION_SHEETS (state, trans) {
      state.sheetsTranslations = trans
    }
  },
  actions: {
    addPricing ({ commit }, pricing) {
      return new Promise((resolve, reject) => {
        axios
          .post('/pricings', pricing)
          .then(response => {
            commit('ADD_PRICING', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deletePricing ({ commit }, pricingId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/pricings/${pricingId}`)
          .then(response => {
            commit('REMOVE_PRICING', pricingId)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPricings ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchPricings')) {
        rootGetters.cancelToken('fetchPricings').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchPricings', { root: true })

      return new Promise((resolve, reject) => {
        let url = '/pricings'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios
          .get(url, {
            cancelToken: rootGetters.cancelToken('fetchPricings').token
          })
          .then(response => {
            commit('SET_PRICINGS', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchPricing (context, pricingId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/pricings/${pricingId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updatePricing ({ commit }, pricing) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/pricings/${pricing.id}`, pricing)
          .then(response => {
            commit('UPDATE_PRICING', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchElectricityChargeList ({ commit }) {
      const electricityChargeResource =
        process.env.VUE_APP_ELECTRICITY_CHARGE_RESOURCE
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/pricings?type[exact]=${electricityChargeResource}&state[exact]=published&order[date]=desc&pagination=false`
          )
          .then(response => {
            commit('SET_ELECTRICITYCHARGELIST', response.data['hydra:member'])
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchGasChargeList ({ commit }) {
      const gasChargeResource = process.env.VUE_APP_GAS_CHARGE_RESOURCE
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/pricings?type[exact]=${gasChargeResource}&state[exact]=published&order[date]=desc&pagination=false`
          )
          .then(response => {
            commit('SET_GASCHARGELIST', response.data['hydra:member'])
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSheet (context, sheetId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/sheets/${sheetId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSheets ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchSheets')) {
        rootGetters.cancelToken('fetchSheets').cancel()
      }

      commit('SET_CANCEL_TOKEN', 'fetchSheets', { root: true })

      return new Promise((resolve, reject) => {
        let url = '/sheets'
        if (payload) {
          url += `?${qs.stringify(payload)}`
        }
        axios
          .get(url, {
            cancelToken: rootGetters.cancelToken('fetchSheets').token
          })
          .then(response => {
            commit('SET_SHEETS', response.data['hydra:member'].filter(sheet => !sheet.variant))
            commit('SET_SHEETS_WITH_VARIANT', response.data['hydra:member'])
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addSheet ({ commit }, sheet) {
      return new Promise((resolve, reject) => {
        axios
          .post('/sheets', sheet)
          .then(response => {
            commit('ADD_SHEET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateSheet ({ commit }, sheet) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/sheets/${sheet.id}`, sheet)
          .then(response => {
            commit('UPDATE_SHEET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateContentSheets (_, content) {
      return new Promise((resolve, reject) => {
        const url = `/trans/api/translations/${content._id}`
        const data = qs.stringify(content)
        axios
          .put(url, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchTranslationSheets ({ commit }, domainSheet) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/trans/api/translations?_domain=${domainSheet}&_search=true&page=1&rows=9999`
          )
          .then(response => {
            commit('SET_TRANSLATION_SHEETS', response.data.translations)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    currentSheet: state => (pricing, type, locale, company, version) => {
      return state.sheets[pricing][type][company][locale][version]
    },
    getSheetsVariant: state => (type, locale, company, version) => {
      return state.sheetsWithVariant.filter((sheet) => {
        return sheet.type.name === type &&
          sheet.locale === locale &&
          sheet.company === company &&
          sheet.version === parseInt(version)
      })
    }
  }
}
