<template>
<div id="electricity-standard-form">
  <ValidationObserver v-slot="{ invalid, handleSubmit }">
    <form @submit.prevent="handleSubmit(submitForm)">
      <h2>{{ $t('pricing.form.electricity-standard.title', {'date': formatDate(pricingLocal.date) , 'version': pricingLocal.version }) }}</h2>
      <div class="vx-row my-10">
        <div class="vx-col w-1/6">
        </div>
        <div class="vx-col w-1/3">
          <ValidationProvider class="mt-2 block" :name="$t('pricing.form.electricity-standard.dateStart')" rules="required" v-slot="{ errors, valid, invalid  }">
            <small class="date-label">{{ $t('pricing.form.electricity-standard.dateStart') }}</small>
            <datepicker class="w-full" :monday-first="true" :language="langFr" :fullMonthName="true" :disabled="true" format="dd MMMM yyyy" name="dateStart" v-model="pricingLocal.content.dateStart" :danger="invalid" :dangerText="errors.join('<br/>')"/>
          </ValidationProvider>
        </div>
        <div class="vx-col w-1/3">
          <ValidationProvider class="mt-2 block" :name="$t('pricing.form.input.electricity_charge')" rules="required" v-slot="{ errors, valid, invalid }">
            <vs-select class="w-full" :label="$t('pricing.form.electricity-standard.electricity_charge')" v-model="pricingLocal.content.electricityCharge" autocomplete :success="valid" :danger="invalid" :dangerText="errors.join('<br/>')" :disabled="disabled">
              <vs-select-item :key="item.id" :value="`/pricings/${item.id}`" :text="item.name" v-for="item in electricityChargeList" />
            </vs-select>
          </ValidationProvider>
        </div>
        <PricingActionButton v-if="pricing.state === 'published'" :pricing="pricing" :invalid="invalid" @pricing:new="newVersionPricing" class="mx-auto mt-6" />
      </div>
      <div class="vx-row">
        <vs-tabs alignment="fixed" v-model="currentTab" tag="home" :color="companyColor">
          <vs-tab label="Enovos">
            <vs-tabs alignment="fixed" :color="companyColor">
              <vs-tab :label="$t('pricing.form.electricity-standard.electricity')">
                <electricity-standard-double-inputs :color="companyColor" :disabled="disabled" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.enovos.electricity" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.BHP')">
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="BHP" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.enovos.BHP" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.MT')">
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="MT" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.enovos.MT" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.public_light')">
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="public_light" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.enovos.public_light" />
              </vs-tab>
<!--              <vs-tab :label="$t('pricing.form.electricity-standard.default_supply')">-->
<!--              </vs-tab>-->
<!--              <vs-tab :label="$t('pricing.form.electricity-standard.last_resort_supply')">-->
<!--              </vs-tab>-->
            </vs-tabs>
          </vs-tab>
          <vs-tab label="Leo">
            <vs-tabs alignment="fixed" :color="companyColor">
              <vs-tab :label="$t('pricing.form.electricity-standard.electricity')">
                <electricity-standard-double-inputs :color="companyColor" :disabled="disabled" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.leo.electricity" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.BHP')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'leo', 'BHP')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="BHP" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.leo.BHP" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.MT')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'leo', 'MT')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="MT" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.leo.MT" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.public_light')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'leo', 'public_light')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="public_light" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.leo.public_light" />
              </vs-tab>
            </vs-tabs>
          </vs-tab>
          <vs-tab label="Steinergy">
            <vs-tabs alignment="fixed" :color="companyColor">
              <vs-tab :label="$t('pricing.form.electricity-standard.electricity')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'steinergy', 'electricity')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-double-inputs :color="companyColor" :disabled="disabled" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.steinergy.electricity" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.BHP')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'steinergy', 'BHP')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="BHP" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.steinergy.BHP" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.MT')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'steinergy', 'MT')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="MT" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.steinergy.MT" />
              </vs-tab>
            </vs-tabs>
          </vs-tab>
          <vs-tab label="Nordenergie">
            <vs-tabs alignment="fixed" :color="companyColor">
              <vs-tab :label="$t('pricing.form.electricity-standard.electricity')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'nordenergie', 'electricity')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-double-inputs :color="companyColor" :disabled="disabled" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.nordenergie.electricity" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.BHP')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'nordenergie', 'BHP')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="BHP" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.nordenergie.BHP" />
              </vs-tab>
              <vs-tab :label="$t('pricing.form.electricity-standard.MT')">
                <vs-button type="flat" icon="content_copy" class="absolute z-1000 mt-6 ml-12" :color="companyColor"
                           @click="copyData('enovos', 'nordenergie', 'MT')">
                  {{$t('pricing.form.electricity-standard.copy.from_enovos')}}
                </vs-button>
                <electricity-standard-single-inputs :color="companyColor" :disabled="disabled" header="MT" translation-prefix="pricing.form.electricity-standard.input." v-model="pricingLocal.content.nordenergie.MT" />
              </vs-tab>
              <!--              <vs-tab :label="$t('pricing.form.electricity-standard.default_supply')">-->
              <!--              </vs-tab>-->
            </vs-tabs>
          </vs-tab>
        </vs-tabs>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <PricingActionButton v-if="pricing.state !== 'published'" :pricing="pricing" :invalid="invalid" @pricing:action="submitForm" />
        </div>
      </div>
    </form>
  </ValidationObserver>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'

import { DateTime } from 'luxon'

import ElectricityStandardSingleInputs from './subform/ElectricityStandardSingleInputs'
import ElectricityStandardDoubleInputs from './subform/ElectricityStandardDoubleInputs'
import PricingActionButton from './input/PricingActionButton'

const cloneDeep = require('lodash.clonedeep')

import modulePricingMixin from '@/store/pricing/modulePricingMixin'
import modulePricingTypeMixin from '@/store/pricing/modulePricingTypeMixin'

export default {
  name: 'ElectricityStandardForm',
  components: {
    Datepicker,
    ElectricityStandardSingleInputs,
    ElectricityStandardDoubleInputs,
    PricingActionButton
  },
  mixins: [modulePricingMixin, modulePricingTypeMixin],
  props: {
    pricing: Object
  },
  data () {
    return {
      pricingLocal: {},
      langFr: fr,
      currentTab: 0,
      tabColorList: ['#ff7900', '#005092', '#eb6d0b', '#7aae34']
    }
  },
  computed: {
    electricityChargeList () {
      return this.$store.state.pricing.electricityChargeList
    },
    pricingType: {
      get () {
        return `/pricing_types/${this.pricingLocal.type.id}`
      },
      set (value) {
        this.$store.dispatch('pricingType/fetch', value)
          .then(response => {
            this.pricingLocal.type = response.data
          })
      }
    },
    companyColor () {
      return this.tabColorList[this.currentTab]
    },
    disabled () {
      return this.pricingLocal.state !== 'work_in_progress'
    }
  },
  methods: {
    submitForm (action) {
      if (action === 'reset') {
        this.resetFixForm()
        return
      }

      if (action === 'update' || action === 'submit_pricing_validation') {
        this.pricingLocal.action = action
        this.saveFixForm()
        return
      }

      const pricing = {
        id: this.pricingLocal.id,
        name: this.pricingLocal.name,
        action
      }
      this.$emit('update:pricing', pricing)
    },
    saveFixForm () {
      if (typeof this.pricingLocal.action === 'undefined' || Array.isArray(this.pricingLocal.action)) {
        this.pricingLocal.action = 'update'
      }
      this.pricingLocal.type = this.pricingType
      this.$emit('update:pricing', this.pricingLocal)
    },
    resetFixForm () {
      this.pricingLocal = cloneDeep(this.pricing)
      this.changeDate(new Date(this.pricingLocal.date))
    },
    newVersionPricing () {
      const pricing = {
        type: this.pricingLocal.type['@id'],
        date: this.pricingLocal.date
      }
      this.$emit('post:pricing', pricing)
    },
    changeDate (date) {
      date = DateTime.fromJSDate(date).setZone('UTC')
      this.pricingLocal.content.dateStart = date.toISODate()
    },
    formatDate (date) {
      let dateTime = DateTime

      if (typeof date === 'string') {
        dateTime = DateTime.fromISO(date)
      } else if (typeof date === 'object') {
        dateTime = DateTime.fromJSDate(date)
      } else {
        return date
      }

      return dateTime.toLocaleString({ locale: 'fr', month: 'long', year: 'numeric'})
    },
    copyData (from, to, type) {
      if (typeof this.pricingLocal.content[to] === 'undefined' || typeof this.pricingLocal.content[to][type] === 'undefined') {
        this.$vs.notify({
          color: 'danger',
          icon: 'error',
          title: this.$t('pricing.form.electricity-standard.copy.notify.error_to.title'),
          text: this.$t('pricing.form.electricity-standard.copy.notify.error_to.content', {from, to, type})
        })
        return
      }

      if (typeof this.pricingLocal.content[from] === 'undefined' || typeof this.pricingLocal.content[from][type] === 'undefined') {
        this.$vs.notify({
          color: 'danger',
          icon: 'error',
          title: this.$t('pricing.form.electricity-standard.copy.notify.error_from.title'),
          text: this.$t('pricing.form.electricity-standard.copy.notify.error_from.content', {from, to, type})
        })
        return
      }

      this.pricingLocal.content[to][type] = cloneDeep(this.pricingLocal.content[from][type])

      this.$vs.notify({
        color: 'success',
        title: this.$t('pricing.form.electricity-standard.copy.notify.success.title'),
        text: this.$t('pricing.form.electricity-standard.copy.notify.success.content', {from, to, type})
      })
    }
  },
  created () {
    this.resetFixForm()
    this.$store.dispatch('pricing/fetchElectricityChargeList')
  }
}
</script>

<style scoped>
.z-1000 {
  z-index: 1000 !important;
}
</style>
