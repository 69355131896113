<template class="comment">
  <div>
    <h2>
      {{ $t("comment.title") }}
    </h2>
    <p class="text-md my-2">
      {{ $t("comment.intro") }}
    </p>
    <section class="comment-content-area">
      <component
        :is="scrollbarTag"
        :key="$vs.rtl"
        ref="commentLogPS"
        :settings="settings"
        class="comment-content-scroll-area">
        <div ref="commentLog">
          <div v-if="comments" class="m-8 space-y-4">
            <div v-for="(comment, index) in comments" :key="index">
              <span v-if="index == 0" class="flex justify-center my-10">
                {{ $t("comment.createdAt") }} {{ dateFormatter(comment.createdAt) }}
              </span>
              <!-- If previous msg is older than current time -->
              <template v-if="comments[index - 1]">
                <vs-divider v-if="!isSameDay(comment.createdAt, comments[index - 1].createdAt)">
                  <span>{{ dateFormatter(comment.createdAt) }}</span>
                </vs-divider>
                <div v-if="!hasSentPreviousComment(comments[index - 1].createdBy, comment.createdBy)" class="spacer mt-8">
                </div>
              </template>

              <div :class="[{'flex-row-reverse': comment.createdBy.id === activeUserInfo.id}]" class="flex items-center">
                <template v-if="comments[index - 1]">
                  <template v-if="!hasSentPreviousComment(comments[index - 1].createdBy, comment.createdBy) ||
                                  !isSameDay(comment.createdAt, comments[index - 1].createdAt)">
                    <vs-avatar
                      :class="comment.createdBy.id === activeUserInfo.id ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'"
                      :src="comment.createdBy.avatar && comment.createdBy.avatar['@id'] ? `${comment.createdBy.fileAvatar}?bearer=${bearer}` : null"
                      :text="comment.createdBy.name"
                      class="self-start border-2 shadow border-solid border-white m-0 flex-shrink-0"
                      size="40px"
                    >
                    </vs-avatar>
                  </template>
                </template>

                <template v-if="index == 0">
                  <vs-avatar
                    :class="comment.createdBy.id === activeUserInfo.id ? 'sm:ml-5 ml-3' : 'sm:mr-5 mr-3'"
                    :src="comment.createdBy.avatar && comment.createdBy.avatar['@id'] ? `${comment.createdBy.fileAvatar}?bearer=${bearer}` : null"
                    :text="comment.createdBy.name"
                    class="self-start border-2 shadow border-solid border-white m-0 flex-shrink-0"
                    size="40px"
                  >
                  </vs-avatar>
                </template>

                <template v-if="comments[index - 1]">
                  <div
                    v-if="!(!hasSentPreviousComment(comments[index - 1].createdBy,comment.createdBy) ||
                          !isSameDay(comment.createdAt,comments[index - 1].createdAt))"
                    class="mr-16"
                  ></div>
                </template>

                <div class="flex flex-col space-y-1">
                  <span
                    v-if="comment.createdAt !== comment.updatedAt"
                    class="self-end text-xs"
                  >
                    {{ $t("comment.updatedAt") }}
                    {{
                      dateFormatter(comment.updatedAt) +
                      $t("comment.updatedAt.word") +
                      timeFormatter(comment.updatedAt)
                    }}
                  </span>
                  <div :class="{'bg-primary-gradient text-white': comment.createdAt,
                              'border border-solid border-transparent bg-white': !comment.createdAt}"
                        class="msg break-words relative shadow-md rounded py-3 px-4 rounded-lg"
                  >
                    <div v-if="index === currentComment && isEditing"
                        class="flex flex-col justify-end"
                    >
                      <vs-textarea
                        v-model="commentModel"
                        width="20rem"
                        class="bg-white text-black mb-0"
                      />
                      <vs-button
                        @click="updateComment(comment)"
                        class="px-2 pt-5 self-end"
                        text-color="white"
                        color="white"
                        type="gradient"
                        icon="send"
                      >
                        {{ $t("comment.update") }}
                      </vs-button>
                    </div>
                    <span v-else style="white-space: pre-line">{{ comment.content }}</span>
                  </div>
                  <div class="flex justify-between items-center text-xs space-x-5">
                    <span class="pl-2">{{ timeFormatter(comment.createdAt) }}</span>
                    <div class="flex my-2">
                      <vs-button
                        v-if="comment.createdBy.id === activeUserInfo.id"
                        @click="modeEdit(index)"
                        class="ml-3 h-6 w-5"
                        color="primary"
                        icon="edit"
                        type="flat"
                      />
                      <vs-button
                        v-if="comment.createdBy.id === activeUserInfo.id"
                        @click="deleteCommentDialog(comment.id)"
                        class="h-6 w-5"
                        color="primary"
                        icon="delete"
                        type="flat"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </component>
    </section>
    <ValidationObserver v-slot="{ invalid }">
      <ValidationProvider
        class="comment__input flex items-center bg-white"
        rules="required"
      >
        <vs-textarea
          v-model="typedComment"
          class="flex-1 mb-0"
          width="300px"
          :placeholder="$t('comment.input.placeholder')"
        />
        <vs-button
          :disabled="invalid"
          class="bg-primary-gradient h-12 ml-4"
          type="filled"
          @click="sendComment"
        >
          {{ $t("comment.submit") }}
        </vs-button>
      </ValidationProvider>
    </ValidationObserver>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moduleCommentMixin from '@/store/comment/moduleCommentMixin'

export default {
  name: 'CommentsView',
  mixins: [moduleCommentMixin],
  components: {
    VuePerfectScrollbar
  },
  props: {
    resource: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      typedComment: '',
      currentComment: '',
      isEditing: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      }
    }
  },
  computed: {
    comments () {
      return this.$store.getters['comment/getCommentsByResource'](
        this.resource
      )
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    },
    commentModel: {
      get () {
        return (this.isEditing ? this.comments[this.currentComment].content : '')
      },
      set (value) {
        this.$store.commit('comment/UPDATE_COMMENT_VIEW', {
          content: value,
          index: this.currentComment,
          name: this.resource
        })
      }
    },
    hasSentPreviousComment () {
      return (last_sender, current_sender) => {
        return last_sender['@id'] === current_sender['@id']
      }
    },
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  watch: {
    object () {
      this.$store.dispatch('comment/fetchComments', {
        domain: {
          name: this.resource,
          id: this.object.id
        }
      })
    }
  },
  methods: {
    scrollToBottomCommentArea () {
      const scroll_el = this.$refs.commentLogPS.$el || this.$refs.commentLogPS
      scroll_el.scrollTop = this.$refs.commentLog.scrollHeight
    },
    modeEdit (index) {
      this.currentComment = index
      this.isEditing = !this.isEditing
    },
    isSameDay (time_to, time_from) {
      const date_time_to = new Date(Date.parse(time_to))
      const date_time_from = new Date(Date.parse(time_from))
      return (
        date_time_to.getFullYear() === date_time_from.getFullYear() &&
        date_time_to.getMonth() === date_time_from.getMonth() &&
        date_time_to.getDate() === date_time_from.getDate()
      )
    },
    dateFormatter (date) {
      const options = {
        day: '2-digit',
        month: 'short'
      }
      const dtf = new Intl.DateTimeFormat(undefined, options)
      return dtf.format(new Date(Date.parse(date)))
    },
    timeFormatter (date) {
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      const dtf = new Intl.DateTimeFormat(undefined, options)
      return dtf.format(new Date(Date.parse(date)))
    },
    sendComment () {
      const linkedResource = this.resource.slice(0, -1)
      const comment = {
        content: this.typedComment
      }
      comment[linkedResource] = this.object['@id']
      this.$store.dispatch('comment/addComment', {
        name: this.resource,
        comment
      }).then(() => {
        this.typedComment = ''
        this.scrollToBottomCommentArea()
      })
    },
    updateComment (comment) {
      this.isEditing = false
      const linkedResource = this.resource.slice(0, -1)
      comment[linkedResource] = this.object['@id']
      this.$store.dispatch('comment/updateComment', {
        id: comment.id,
        comment,
        name: this.resource
      })
    },
    deleteCommentDialog (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('comment.delete.confirm.dialog.title'),
        text: this.$t('comment.delete.confirm.dialog.text'),
        accept: this.deleteComment,
        parameters: id,
        acceptText: this.$t('comment.delete.confirm.dialog.acceptText'),
        cancelText: this.$t('comment.delete.confirm.dialog.cancelText')
      })
    },
    deleteComment (id) {
      this.$store
        .dispatch('comment/deleteComment', {
          name: this.resource,
          id
        })
        .then(() => {
          this.showDeleteSuccess()
        })
        .catch((err) => {
          console.error(err) // eslint-disable-line no-console
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: this.$t('comment.delete.confirm.notify.title'),
        text: this.$t('comment.delete.confirm.notify.text')
      })
    }
  },
  async created () {
    await this.$store.dispatch('comment/fetchComments', {
      domain: {
        name: this.resource,
        id: this.object.id
      }
    }).then(() => {
      this.scrollToBottomCommentArea()
    })
  }
}
</script>

<style lang="scss">
.comment {
  flex-basis: 25%;
}
.comment-content-area {
  position: relative;

  .comment-content-scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100% - 134px);
    max-height: 500px;

    @media (min-width: 1800px){
      max-height: 810px;
    }

    &:not(.ps) {
      overflow-y: hidden;
    }

    .comment__input {
      position: relative;
      bottom: 0;
      width: 100%;
    }
  }
}
.vs-textarea {
  min-height: 8rem;
}
</style>
