<template>
  <div id="electricity-charge-form">
    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <form @submit.prevent="handleSubmit(validateForm)">
        <h2>{{ $t('pricing.form.electricity-charge.title', {'date': formatDate(pricingLocal.date) , 'version': pricingLocal.version }) }}</h2>
        <div class="my-10 flex items-center justify-center space-x-5">
          <ValidationProvider :name="$t('pricing.form.input.type')" rules="required" v-slot="{ errors }">
            <pricing-type-select :label="$t('pricing.form.input.type')" v-model="pricingType" disabled/>
            <span class="error-class">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider :name="$t('pricing.form.input.date')" rules="required" v-slot="{ errors, valid, invalid  }">
            <small class="date-label">{{ $t('pricing.form.input.date') }}</small>
            <datepicker :disabled="true" :monday-first="true" :language="langFr" :fullMonthName="true" minimumView="month" initialView="month" format="MMMM yyyy" name="date" v-model="pricingLocal.date" :success="valid" :danger="invalid" :dangerText="errors.join('<br/>')"/>
          </ValidationProvider>
        </div>
        <PricingActionButton v-if="pricing.state === 'published'" :pricing="pricing" :invalid="invalid" @pricing:new="newVersionPricing" class="mx-auto justify-center" />
        <div class="vx-row my-10">
          <div class="vx-col w-full">
            <charge-inputs v-model="pricingLocal.content" translationPrefix="pricing.form.electricity-charge.input." :disabled="disabled" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <PricingActionButton v-if="pricing.state !== 'published'" :pricing="pricing" :invalid="invalid" @pricing:action="submitForm" />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'

import { DateTime } from 'luxon'

import PricingTypeSelect from './input/PricingTypeSelect'
import ChargeInputs from './subform/ChargeInputs'
import PricingActionButton from './input/PricingActionButton'

const cloneDeep = require('lodash.clonedeep')

import modulePricingTypeMixin from '@/store/pricing/modulePricingTypeMixin'

export default {
  name: 'ElectricityChargeForm',
  components: {
    Datepicker,
    PricingTypeSelect,
    ChargeInputs,
    PricingActionButton
  },
  mixins: [modulePricingTypeMixin],
  props: {
    pricing: Object
  },
  data () {
    return {
      pricingLocal: {},
      langFr: fr
    }
  },
  computed: {
    pricingType: {
      get () {
        return `/pricing_types/${this.pricingLocal.type.id}`
      },
      set (value) {
        this.$store.dispatch('pricingType/fetch', value)
          .then(response => {
            this.pricingLocal.type = response.data
          })
      }
    },
    disabled () {
      return this.pricingLocal.state !== 'work_in_progress'
    }
  },
  methods: {
    submitForm (action) {
      if (action === 'reset') {
        this.resetForm()
        return
      }

      if (action === 'update' || action === 'submit_pricing_validation') {
        this.pricingLocal.action = action
        this.saveForm()
        return
      }

      const pricing = {
        id: this.pricingLocal.id,
        name: this.pricingLocal.name,
        action
      }
      this.$emit('update:pricing', pricing)
    },
    saveForm () {
      if (typeof this.pricingLocal.action === 'undefined' || Array.isArray(this.pricingLocal.action)) {
        this.pricingLocal.action = 'update'
      }
      this.pricingLocal.type = this.pricingType
      this.$emit('update:pricing', this.pricingLocal)
    },
    resetForm () {
      this.pricingLocal = cloneDeep(this.pricing)
    },
    newVersionPricing () {
      const pricing = {
        type: this.pricingLocal.type['@id'],
        date: this.pricingLocal.date
      }
      this.$emit('post:pricing', pricing)
    },
    formatDate (date) {
      let dateTime = DateTime

      if (typeof date === 'string') {
        dateTime = DateTime.fromISO(date)
      } else if (typeof date === 'object') {
        dateTime = DateTime.fromJSDate(date)
      } else {
        return date
      }

      return dateTime.toLocaleString({ locale: 'fr', month: 'long', year: 'numeric'})
    }
  },
  created () {
    this.resetForm()
  }
}
</script>

<style scoped>

</style>
