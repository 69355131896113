<template>
  <div class="flex flex-wrap items-center justify-end">
    <vs-button v-if="hasAction('submit_pricing_validation')" @click.prevent="onClick('reset')"
               class="mt-2" type="border" color="warning">
      {{ $t('pricing.edit.button.reset') }}
    </vs-button>
    <vs-button v-if="hasAction('update')" @click.prevent="onClick('update')"
               button="submit" class="ml-4 mt-2" icon="save">
      {{ $t('pricing.edit.button.save_submit') }}
    </vs-button>
    <vs-button v-if="hasAction('submit_pricing_validation')" @click.prevent="onClick('submit_pricing_validation')"
               button="submit" class="ml-4 mt-2" :disabled="invalid" color="success" icon="done">
      {{ $t('pricing.edit.button.pricing_validation_submit') }}
    </vs-button>
    <vs-button v-if="hasAction('pricing_validator_reject')" @click.prevent="promptPostComment('pricing_validator_reject')"
               class="ml-4 mt-2" color="danger" icon="thumb_down">
      {{ $t('pricing.edit.button.pricing_reject_submit') }}
    </vs-button>
    <vs-button v-if="hasAction('pricing_validator_approve')" @click.prevent="onClick('pricing_validator_approve')"
               class="ml-4 mt-2" color="success" icon="thumb_up">
      {{ $t('pricing.edit.button.pricing_validated_submit') }}
    </vs-button>
    <vs-button v-if="hasAction('to_correct_pricing') && !hasState('published')" @click.prevent="promptPostComment('to_correct_pricing')"
               class="ml-4 mt-2" color="danger" icon="thumb_down">
      {{ $t('pricing.edit.button.to_correct_pricing') }}
    </vs-button>
    <vs-button v-if="hasState('published')" @click.prevent="onNewPricing('new_pricing')"
               color="warning" icon="add">
      {{ $t('pricing.edit.button.new_pricing_version') }}
    </vs-button>
    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        :title="$t('comment.action_reject.dialog.title')"
        :accept-text="$t(`comment.${actionInProgress}.dialog.acceptText`)"
        :active.sync="activePromptPostComment"
        :is-valid="!invalid"
        color="danger"
        @accept="postCommentAndRejectPricing(actionInProgress)">
        <p class="mb-4">
          {{ $t('comment.action_reject.dialog.text') }}
        </p>
        <ValidationProvider rules="required">
          <vs-textarea v-model="commentPayload.content" :placeholder="$t('comment.input.placeholder')" />
        </ValidationProvider>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'PricingActionButton',
  props: {
    pricing: {
      type: Object,
      required: true
    },
    invalid: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      commentPayload: {
        content: ''
      },
      activePromptPostComment: false,
      actionInProgress: ''
    }
  },
  methods: {
    onClick (action) {
      this.$emit('pricing:action', action)
    },
    async postCommentAndRejectPricing (action) {
      this.commentPayload = {
        ...this.commentPayload,
        pricing: this.pricing['@id']
      }
      try {
        await this.$store.dispatch('comment/addComment', { comment: this.commentPayload })
        this.$vs.notify({
          color: 'success',
          title: this.$t('comment.post.confirm.notify.title'),
          text: this.$t('comment.post.confirm.notify.text')
        })
        this.$emit('pricing:action', action)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
        this.$vs.notify({
          color: 'danger',
          title: this.$t('comment.post.confirm.error.notify.title'),
          text: this.$t('comment.post.confirm.error.notify.text', { error: error.response.data['hydra:description'] }),
          fixed: true
        })
      }
    },
    onNewPricing () {
      this.$emit('pricing:new')
    },
    promptPostComment (action) {
      this.actionInProgress = action
      this.activePromptPostComment = true
    },
    hasAction (action) {
      return this.pricing.action.includes(action)
    },
    hasState (state) {
      return this.pricing.state.includes(state)
    }
  }
}
</script>
<style lang="scss">
.vs-textarea {
  max-height: 400px;
}
</style>
