import modulePricingType from './modulePricingType'

export default {
  created () {
    if (!modulePricingType.isRegistered) {
      this.$store.registerModule('pricingType', modulePricingType)
      modulePricingType.isRegistered = true
    }
  }
}
