<template>
  <div>
    <div
      v-if="hasAction('submit')"
      class="flex justify-center space-x-5"
    >
      <vs-button
        @click.prevent="onClickUpdateSheet('submit')"
        :disabled="loading"
        type="border"
        color="warning"
        icon="done"
      >
        {{
          $t("pricing.sheet.button.content_modification.only_this_sheet", {
            company: sheet.company,
          })
        }}
      </vs-button>
      <vs-button
        @click.prevent="onClickUpdateSheets('submit')"
        :disabled="loading"
        type="border"
        color="warning"
        icon="list"
      >
        {{
          $t("pricing.sheet.button.content_modification.all_sheets", {
            sheet_type: $t(`pricing.sheet.type.${sheet.type.name}`),
          })
        }}
      </vs-button>
    </div>
    <vs-table
      v-if="lexikLocal.length"
      class="w-full mt-10"
      :data="lexikLocal"
      :mtganotSpacer="true"
    >
      <template slot="thead">
        <vs-th class="text-lg w-1/4">{{
          $t("pricing.sheet.table.content_modification.translation_key")
        }}</vs-th>
        <vs-th class="text-lg">{{
          $t("pricing.sheet.table.content_modification.translation_value")
        }}</vs-th>
        <vs-th class="text-lg w-10"></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr]">
            {{ data[indextr]._key }}
          </vs-td>
          <vs-td :data="data[indextr]">
            <vs-input v-model="sheetLocal.content[data[indextr]._key]" @change="updateSheet(sheetLocal.content[data[indextr]._key], lexikTranslations[indextr])" class="w-full" :disabled="isContentValidator" />
          </vs-td>
          <vs-td :data="lexikTranslations">
            <vs-tooltip
              v-for="(locale, key) in displayFlags(lexikTranslations[indextr])"
              :key="key"
              :text="locale"
              position="top"
            >
              <img @click="key === sheetLocal.locale ? updateContentInput(data[indextr]._key, lexikTranslations[indextr]) : null"
                   :src="require(`@/assets/images/flags/${key}.png`)"
                   :class="key === sheetLocal.locale ? 'cursor-pointer' : ''"
                   class="w-5 h-5"
              />
            </vs-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import modulePricingMixin from '@/store/pricing/modulePricingMixin'

const cloneDeep = require('lodash.clonedeep')
export default {
  mixins: [modulePricingMixin],
  props: {
    sheet: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      sheetLocal: null,
      lexikLocal: [],
      onlyModifiedInputs: []
    }
  },
  computed: {
    lexikTranslations () {
      return this.$store.state.pricing.sheetsTranslations
    },
    isContentValidator () {
      return this.sheetLocal.action < 1
    }
  },
  methods: {
    updateContentInput (key, lexik) {
      this.sheetLocal.content[key] = lexik[this.sheetLocal.locale]
      this.updateSheet(this.sheetLocal.content[key], lexik)
    },
    onClickUpdateSheet (action) {
      const sheetContent = this.sheetLocal.content
      this.$emit('sheet:update-content-sheet', {
        id: this.sheet.id,
        company: this.sheet.company,
        content: sheetContent,
        action
      })
    },
    onClickUpdateSheets (action) {
      const sheetContent = this.sheetLocal.content
      this.$emit('sheet:update-content-sheets', {
        id: this.sheet.id,
        domain: this.sheet.domain,
        content: sheetContent,
        contents: this.onlyModifiedInputs,
        action
      })
    },
    updateSheet (value, lexik) {
      const obj = {
        _id: lexik._id,
        _key: lexik._key,
        _domain: lexik._domain,
        [this.sheet.locale]: value
      }
      const position = this.onlyModifiedInputs.findIndex((el) => el._id === obj._id)
      if (position === -1) {
        this.onlyModifiedInputs.push(obj)
      } else {
        this.onlyModifiedInputs[position][this.sheet.locale] = value
      }
    },
    fetchLexikTranslations () {
      this.$store.dispatch('pricing/fetchTranslationSheets', this.sheet.domain).then((res) => {
        this.lexikLocal = cloneDeep(res.data.translations)
      }).catch((err) => {
        console.error(err) // eslint-disable-line no-console
      })
    },
    hasAction (action) {
      return this.sheet.action.includes(action)
    },
    displayFlags (lexik) {
      return {
        fr: lexik.fr,
        en: lexik.en,
        de: lexik.de
      }
    }
  },
  created () {
    this.sheetLocal = cloneDeep(this.sheet)
    this.fetchLexikTranslations()
  }
}
</script>

<style>
</style>
