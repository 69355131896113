<template>
  <div id="sheet-view">
    <vs-tabs
      color="rgba(var(--vs-primary),1)"
      alignment="fixed"
      v-if="Object.keys(sheets).length"
      v-model="tabPosition"
    >
      <vs-tab
        v-for="(sheet, type) in sheets"
        class="2xl:flex"
        :label="$t(`pricing.sheet.type.${type}`)"
        :key="type"
      >
        <vx-card class="sheetview flex flex-col justify-center">
          <div class="flex w-full justify-center space-x-5 mt-10">
            <vs-select
              :label="$t('pricing.sheet.input.filter.company')"
              v-model="currentCompany"
              @input="updateCompany(type)"
              >
              <vs-select-item
                v-for="companyName in Object.keys(sheet)"
                :key="companyName"
                :value="companyName"
                :text="companyName"
              />
            </vs-select>
            <vs-select
              :label="$t('pricing.sheet.input.filter.locale')"
              v-model="locale"
            >
              <vs-select-item
                v-for="l in Object.keys(sheet[company[type]])"
                :key="l"
                :value="l"
                :text="l"
              />
            </vs-select>
            <vs-select
              :label="$t('pricing.sheet.input.filter.version')"
              v-model="currentVersion"
            >
              <vs-select-item
                v-for="version in Object.keys(sheet[company[type]][locale])"
                :key="version"
                :value="version"
                :text="`V${version}`"
              />
            </vs-select>
            <vs-select
              v-if="_has(sheet, [company[type], locale, currentVersion])"
              :label="$t('pricing.sheet.input.filter.variant')"
              v-model="currentVariant"
              :disabled="onModificationView(currentSheet)"
            >
              <vs-select-item
                :text="$t('pricing.sheet.input.filter.main_sheet')"
                @click="currentVariant === null"
              />
              <vs-select-item
                v-for="sheetV in getSheetsVariant"
                :key="sheetV.id"
                :value="sheetV"
                :text="sheetV.variant"
              />
            </vs-select>
          </div>
          <div v-if="_has(sheet, [company[type], locale, currentVersion])">
            <div v-if="!currentVariant" class="w-full my-8">
              <PricingActionButton v-if="pricing.state === 'published' && currentSheet.state === 'published'" :pricing="pricing" @pricing:new="newVersionPricing" class="mx-auto justify-center" />
              <sheet-action-button
                v-if="currentSheet.state !== 'content_modification'"
                :sheet="currentSheet"
                :pricing="pricing"
                @sheet:action="submitAction"
                @sheet:new-version="createNewVersion"
                @pricing:correct-pricing="correctPricing"
                :loading="isLoading"
              />
              <sheet-translation-inputs
                v-else
                :sheet="currentSheet"
                @sheet:update-content-sheet="submitContentSheet"
                @sheet:update-content-sheets="submitContentSheets"
                :loading="isLoading"
              />
            </div>
            <div v-if="currentSheet.fileUrl &&
                       currentSheet.state !== 'pending_for_assembly' &&
                       currentSheet.state !== 'assembling'"
                       class="overflow-hidden">
              <iframe
                v-if="!currentVariant"
                class="w-full"
                :class="{ 'simple-page': currentSheet.isCharge }"
                :src="`${
                  currentSheet.fileUrl
                }?bearer=${bearer}`"
              ></iframe>
              <iframe
                v-else
                class="w-full"
                :src="`${currentVariant.fileUrl}?bearer=${bearer}`"
                ></iframe>
            </div>
            <div v-if="currentSheet.state === 'pending_for_assembly' ||
                       currentSheet.state === 'assembling'">
              <div class="w-max flex flex-col items-center mx-auto">
                <p class="mb-3">
                  {{ isAssembling ?
                  $t('pricing.sheet.assembling') :
                  $t('pricing.sheet.pending_for_assembly') }}</p>
                <vs-button @click="refreshSheet(currentSheet)">{{ $t('pricing.sheet.button.refresh_sheet') }}</vs-button>
                <vs-progress indeterminate :height="2" color="primary" class="mt-4"></vs-progress>
              </div>
            </div>

            <div class="vx-row my-4">
              <div class="vx-col w-full">
                <sheet-action-button
                  v-if="currentSheet.state !== 'content_modification' &&
                        !currentVariant"
                  :sheet="currentSheet"
                  :pricing="pricing"
                  @sheet:action="submitAction"
                  @sheet:new-version="createNewVersion"
                  @pricing:correct-pricing="correctPricing"
                  :loading="isLoading"
                />
              </div>
            </div>
            <span
              v-show="false">
              {{ currentSheet.id }}
            </span>
          </div>
        </vx-card>
        <vx-card class="mt-5 2xl:mt-0 2xl:mx-5 2xl:px-5 comment">
          <comments-view resource="sheets" :object="currentSheet"/>
        </vx-card>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
const has = require('lodash.has')

import modulePricingMixin from '@/store/pricing/modulePricingMixin'
import SheetActionButton from './input/SheetActionButton'
import PricingActionButton from './input/PricingActionButton'
import CommentsView from '@/components/comments/CommentsView'
import SheetTranslationInputs from './subform/TranslationSheetsInputs'

export default {
  name: 'SheetView',
  components: { SheetActionButton, CommentsView, SheetTranslationInputs, PricingActionButton },
  mixins: [modulePricingMixin],
  props: {
    pricing: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sheets: {},
      company: {},
      currentCompany: null,
      currentVersion: 1,
      currentVariant: null,
      locale: 'fr',
      tabPosition: 0,
      isLoading: false,
      contentSheetIsValid: true,
      isAssembling: false
    }
  },
  computed: {
    currentSheet () {
      return this.$store.getters['pricing/currentSheet'](this.pricing['@id'], this.currentType, this.locale, this.currentCompany, this.currentVersion)
    },
    sheetsVariant () {
      return this.$store.state.pricing.sheetsWithVariant
    },
    getSheetsVariant () {
      return this.$store.getters['pricing/getSheetsVariant'](this.currentType, this.locale, this.currentCompany, this.currentVersion)
    },
    currentType () {
      return Object.keys(this.sheets)[this.tabPosition]
    },
    bearer () {
      return this.$store.getters['auth/accessToken']
    }
  },
  methods: {
    fetchSheets () {
      const payload = {
        pricing: this.pricing.id,
        order: {
          ['company']: 'asc',
          ['type.name']: 'asc',
          ['variant']: 'desc',
          ['locale']: 'asc'
        },
        pagination: false
      }
      this.$store.dispatch('pricing/fetchSheets', payload).then(() => {
        const pricingIRI = `/pricings/${payload.pricing}`
        if (
          typeof this.$store.state.pricing.sheets[pricingIRI] === 'undefined'
        ) {
          this.sheets = {}
        }
        this.sheets = this.$store.state.pricing.sheets[pricingIRI]

        if (!this.isAssembling) {
        // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(this.sheets)) {
            this.company[key] = 'enovos'
            if (key.endsWith('stroum-immo')) {
              this.company[key] = 'leo'
            }
            if (key.endsWith('leo')) {
              this.company[key] = 'leo'
            }
          }

          if (this.currentCompany === null) {
            this.currentCompany = 'enovos'
          } else {
            const companyList = Object.keys(this.sheets[this.currentType])
            const key = companyList.findIndex(company => this.currentCompany === company)

            if (key !== -1 && typeof companyList[key + 1] !== 'undefined') { // go to next version
              this.currentCompany = companyList[key + 1]
              this.$set(this.company, this.currentType, this.currentCompany)
            } else {
              this.currentCompany = companyList[0]
            }
          }
        }
        this.currentVersion = this.getLastVersion()

      })
    },
    async submitAction (payload) {
      this.isLoading = true
      await this.$store
        .dispatch('pricing/updateSheet', payload)
        .then((response) => {
          if (response.data.version === 1 &&
              response.data.state !== 'content_modification') {
            this.moveToNextSheet()
          }
          this.$emit('fetch:pricing', this.pricing.id)
          this.isLoading = false
          this.$vs.notify({
            color: 'success',
            title: this.$t(
              `pricing.form.${payload.action}.success.notify.title`
            ),
            text: this.$t(
              `pricing.form.${payload.action}.success.notify.text`,
              { pricingName: payload.name }
            )
          })
        })
        .catch((error) => {
          this.fetchSheets()
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t(
              'administration.userform.form.submit.error.notify.title'
            ),
            text: this.$t(
              'administration.userform.form.submit.error.notify.text',
              { error: error.response.data['hydra:description'] }
            ),
            fixed: true
          })
        })
    },
    submitContentSheet (payload) {
      this.isLoading = true
      this.$store
        .dispatch('pricing/updateSheet', payload)
        .then(() => {
          this.isLoading = false
          this.$vs.notify({
            color: 'success',
            title: this.$t('pricing.sheet.submit.content.success.notify.title'),
            text: this.$t('pricing.sheet.submit.content.success.notify.text', {
              sheet: payload.company
            })
          })
        })
        .catch((error) => {
          this.fetchSheets()
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('pricing.sheet.submit.content.error.notify.title'),
            text: this.$t('pricing.sheet.submit.content.error.notify.text', {
              error: error.response.data['hydra:description']
            }),
            fixed: true
          })
        })
    },
    async submitContentSheets (payload) {
      this.isLoading = true
      await this.$store
        .dispatch('pricing/updateSheet', payload)
        .then(async () => {
          await Promise.all(
            payload.contents.map((content) => {
              this.$store.dispatch('pricing/updateContentSheets', content)
            })
          ).then(() => {
            this.isLoading = false
            this.$vs.notify({
              color: 'success',
              title: this.$t(
                'pricing.sheet.submit.content.success.notify.title'
              ),
              text: this.$t(
                'pricing.sheet.submit.content.success.notify.text',
                { company: payload.company }
              )
            })
          })
        })
        .catch((error) => {
          this.fetchSheets()
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t(
              'pricing.sheet.submit.content.error.notify.title'
            ),
            text: this.$t(
              'pricing.sheet.submit.content.error.notify.text',
              {
                error: error.response.data['hydra:description']
              }
            ),
            fixed: true
          })
        })
    },
    async createNewVersion (sheet) {
      this.isAssembling = false
      sheet = {
        pricing: sheet.pricing,
        locale: sheet.locale,
        type: `/sheet_types/${sheet.type.id}`,
        company: sheet.company
      }
      const { data } = await this.$store.dispatch('pricing/addSheet', sheet)
      this.currentVersion = data.version
    },
    async refreshSheet (sheet) {
      const { data } = await this.$store.dispatch('pricing/fetchSheet', sheet.id)
      if (data.state === 'assembling' || data.fileUrl) {
        this.isAssembling = true
      }
      if (data.fileUrl) {
        this.fetchSheets()
      }
    },
    correctPricing (action) {
      const pricing = {
        id: this.pricing.id,
        name: this.pricing.name,
        action
      }
      this.$emit('update:pricing', pricing)
    },
    newVersionPricing () {
      const pricing = {
        type: this.pricing.type['@id'],
        date: this.pricing.date
      }
      this.$emit('post:pricing', pricing)
    },
    _has (one, two) {
      return has(one, two)
    },
    onModificationView (sheet) {
      return sheet.state === 'content_modification'
    },
    updateCompany (type) {
      this.$set(this.company, type, this.currentCompany)
    },
    moveToNextSheet () {
      this.isAssembling = false
      this.fetchSheets()
    },
    getLastVersion () {
      const verstionList = Object.keys(
        this.sheets[this.currentType][this.currentCompany][this.locale]
      )
      return verstionList[verstionList.length - 1]
    }
  },
  watch: {
    tabPosition () {
      this.currentVariant = null
      this.currentCompany = this.company[this.currentType]
      this.currentVersion = this.getLastVersion()
    },
    currentCompany () {
      this.currentVariant = null
      this.currentVersion = this.getLastVersion()
    },
    locale () {
      this.currentVariant = null
      this.currentVersion = this.getLastVersion()
    }
  },
  created () {
    this.fetchSheets()
  }
}
</script>

<style lang="scss">
iframe {
  display: block;
  height: 120vh !important;
}
.sheetview {
  @media (min-width: 1800px){
    flex-basis: 100%;
  }
}
.comment {
  flex-basis: 30%;
}
</style>
