<template>
  <div id="gas-standard-form">
    <ValidationObserver v-slot="{ invalid, handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)">
        <h2>{{ $t('pricing.form.gas-standard.title', {'date': formatDate(pricingLocal.date) , 'version': pricingLocal.version }) }}</h2>
        <div class="my-10 flex justify-center space-x-5">
          <ValidationProvider class="block" :name="$t('pricing.form.gas-standard.dateStart')" rules="required" v-slot="{ errors, valid, invalid  }">
            <small class="date-label">{{ $t('pricing.form.gas-standard.dateStart') }}</small>
            <datepicker class="w-full" :monday-first="true" :language="langFr" :fullMonthName="true" :disabled="true" format="dd MMMM yyyy" name="dateStart" v-model="pricingLocal.content.dateStart" :danger="invalid" :dangerText="errors.join('<br/>')"/>
          </ValidationProvider>
          <ValidationProvider class="block" :name="$t('pricing.form.input.gas_charge')" rules="required" v-slot="{ errors, valid, invalid }">
            <vs-select :label="$t('pricing.form.gas-standard.gas_charge')" v-model="pricingLocal.content.gasCharge" autocomplete :success="valid" :danger="invalid" :dangerText="errors.join('<br/>')" :disabled="disabled">
              <vs-select-item :key="item.id" :value="`/pricings/${item.id}`" :text="item.name" v-for="item in gasChargeList" />
            </vs-select>
          </ValidationProvider>
        </div>
        <PricingActionButton v-if="pricing.state === 'published'" :pricing="pricing" :invalid="invalid" @pricing:new="newVersionPricing" class="mx-auto my-5 justify-center" />
        <div class="vx-row">
          <vs-tabs alignment="fixed" v-model="currentTab" tag="home" :color="companyColor">
            <vs-tab label="Enovos">
              <vs-tabs alignment="fixed" :color="companyColor">
                <vs-tab :label="$t('pricing.form.gas-standard.home')">
                  <gas-standard-inputs :color="companyColor" :disabled="disabled" header="home" translation-prefix="pricing.form.gas-standard.input.home." v-model="pricingLocal.content.enovos.home" />
                </vs-tab>
                <vs-tab :label="$t('pricing.form.gas-standard.pro')">
                  <gas-standard-inputs :color="companyColor" :disabled="disabled" header="pro" translation-prefix="pricing.form.gas-standard.input.pro." v-model="pricingLocal.content.enovos.pro" />
                </vs-tab>
                <vs-tab :label="$t('pricing.form.gas-standard.supply')">
                  <gas-standard-inputs :color="companyColor" :disabled="disabled" header="supply" translation-prefix="pricing.form.gas-standard.input.supply." v-model="pricingLocal.content.enovos.supply" />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
            <vs-tab label="Leo">
              <vs-tabs alignment="fixed" :color="companyColor">
                <vs-tab :label="$t('pricing.form.gas-standard.home')">
                  <gas-standard-inputs :color="companyColor" :disabled="disabled" header="home" translation-prefix="pricing.form.gas-standard.input.home." v-model="pricingLocal.content.leo.home" />
                </vs-tab>
                <vs-tab :label="$t('pricing.form.gas-standard.pro')">
                  <gas-standard-inputs :color="companyColor" :disabled="disabled" header="pro" translation-prefix="pricing.form.gas-standard.input.pro." v-model="pricingLocal.content.leo.pro" />
                </vs-tab>
              </vs-tabs>
            </vs-tab>
          </vs-tabs>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <PricingActionButton v-if="pricing.state !== 'published'" :pricing="pricing" :invalid="invalid" @pricing:action="submitForm" />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { fr } from 'vuejs-datepicker/src/locale'

import { DateTime } from 'luxon'

import GasStandardInputs from './subform/GasStandardInputs'
import PricingActionButton from './input/PricingActionButton'

const cloneDeep = require('lodash.clonedeep')

import modulePricingMixin from '@/store/pricing/modulePricingMixin'
import modulePricingTypeMixin from '@/store/pricing/modulePricingTypeMixin'

export default {
  name: 'gasStandardForm',
  components: {
    Datepicker,
    GasStandardInputs,
    PricingActionButton
  },
  mixins: [modulePricingMixin, modulePricingTypeMixin],
  props: {
    pricing: Object
  },
  data () {
    return {
      pricingLocal: {},
      langFr: fr,
      currentTab: 0,
      tabColorList: ['#ff7900', '#005092', '#eb6d0b', '#7aae34']
    }
  },
  computed: {
    gasChargeList () {
      return this.$store.state.pricing.gasChargeList
    },
    pricingType: {
      get () {
        return `/pricing_types/${this.pricingLocal.type.id}`
      },
      set (value) {
        this.$store.dispatch('pricingType/fetch', value)
          .then(response => {
            this.pricingLocal.type = response.data
          })
      }
    },
    companyColor () {
      return this.tabColorList[this.currentTab]
    },
    disabled () {
      return this.pricingLocal.state !== 'work_in_progress'
    }
  },
  methods: {
    submitForm (action) {
      if (action === 'reset') {
        this.resetFixForm()
        return
      }

      if (action === 'update' || action === 'submit_pricing_validation') {
        this.pricingLocal.action = action
        this.saveFixForm()
        return
      }

      const pricing = {
        id: this.pricingLocal.id,
        name: this.pricingLocal.name,
        action
      }
      this.$emit('update:pricing', pricing)
    },
    saveFixForm () {
      if (typeof this.pricingLocal.action === 'undefined' || Array.isArray(this.pricingLocal.action)) {
        this.pricingLocal.action = 'update'
      }
      this.pricingLocal.type = this.pricingType
      this.$emit('update:pricing', this.pricingLocal)
    },
    resetFixForm () {
      this.pricingLocal = cloneDeep(this.pricing)
      this.changeDate(new Date(this.pricingLocal.date))
    },
    newVersionPricing () {
      const pricing = {
        type: this.pricingLocal.type['@id'],
        date: this.pricingLocal.date
      }
      this.$emit('post:pricing', pricing)
    },
    changeDate (date) {
      date = DateTime.fromJSDate(date).setZone('UTC')
      this.pricingLocal.content.dateStart = date.toISODate()
    },
    formatDate (date) {
      let dateTime = DateTime

      if (typeof date === 'string') {
        dateTime = DateTime.fromISO(date)
      } else if (typeof date === 'object') {
        dateTime = DateTime.fromJSDate(date)
      } else {
        return date
      }

      return dateTime.toLocaleString({ locale: 'fr', month: 'long', year: 'numeric'})
    },
    copyData (from, to, type) {
      if (typeof this.pricingLocal.content[to] === 'undefined' || typeof this.pricingLocal.content[to][type] === 'undefined') {
        this.$vs.notify({
          color: 'danger',
          icon: 'error',
          title: this.$t('pricing.form.gas-standard.copy.notify.error_to.title'),
          text: this.$t('pricing.form.gas-standard.copy.notify.error_to.content', {from, to, type})
        })
        return
      }

      if (typeof this.pricingLocal.content[from] === 'undefined' || typeof this.pricingLocal.content[from][type] === 'undefined') {
        this.$vs.notify({
          color: 'danger',
          icon: 'error',
          title: this.$t('pricing.form.gas-standard.copy.notify.error_from.title'),
          text: this.$t('pricing.form.gas-standard.copy.notify.error_from.content', {from, to, type})
        })
        return
      }

      this.pricingLocal.content[to][type] = cloneDeep(this.pricingLocal.content[from][type])

      this.$vs.notify({
        color: 'success',
        title: this.$t('pricing.form.gas-standard.copy.notify.success.title'),
        text: this.$t('pricing.form.gas-standard.copy.notify.success.content', {from, to, type})
      })
    }
  },
  created () {
    this.resetFixForm()
    this.$store.dispatch('pricing/fetchGasChargeList')
  }
}
</script>

<style scoped>
.z-1000 {
  z-index: 1000 !important;
}
</style>
