import modulePricing from './modulePricing'

export default {
  created () {
    if (!modulePricing.isRegistered) {
      this.$store.registerModule('pricing', modulePricing)
      modulePricing.isRegistered = true
    }
  }
}
