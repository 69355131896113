<template>
  <div class="flex flex-wrap items-center justify-center">
    <vs-button
      v-if="hasAction('content_validator_approve')"
      :disabled="loading"
      @click.prevent="onClick('content_validator_approve')"
      class="ml-4 mt-2"
      color="success"
      icon="thumb_up"
    >
      {{
        $t("pricing.sheet.input.content_validator_approve", {
          sheet: sheet.name,
          company: sheet.company,
        })
      }}
    </vs-button>
    <vs-button
      v-if="hasAction('content_validator_reject')"
      :disabled="loading"
      @click.prevent="promptPostComment('content_validator_reject')"
      class="ml-4 mt-2"
      color="danger"
      icon="thumb_down"
    >
      {{
        $t("pricing.sheet.input.content_validator_reject", {
          sheet: sheet.name,
          company: sheet.company,
        })
      }}
    </vs-button>
    <vs-button
      v-if="hasAction('publisher_validator_approve')"
      :disabled="loading"
      @click.prevent="onClick('publisher_validator_approve')"
      class="ml-4 mt-2"
      color="success"
      icon="thumb_up"
    >
      {{
        $t("pricing.sheet.input.publisher_validator_approve", {
          sheet: sheet.name,
          company: sheet.company,
        })
      }}
    </vs-button>
    <vs-button
      v-if="hasAction('publisher_validator_reject')"
      :disabled="loading"
      @click.prevent="promptPostComment('publisher_validator_reject')"
      class="ml-4 mt-2"
      color="danger"
      icon="thumb_down"
    >
      {{
        $t("pricing.sheet.input.publisher_validator_reject", {
          sheet: sheet.name,
          company: sheet.company,
        })
      }}
    </vs-button>
    <vs-button
      v-if="hasAction('submit_content_validation')"
      :disabled="this.loading"
      @click.prevent="onClick('submit_content_validation')"
      class="ml-4 mt-2"
      color="warning"
      icon="pan_tool"
    >
      {{
        $t("pricing.sheet.input.submit_content_validation", {
          sheet: sheet.name,
          company: sheet.company,
        })
      }}
    </vs-button>
    <vs-button v-if="hasAction('to_correct_pricing') && pricingIsNotPublished" 
               @click.prevent="promptPostComment('to_correct_pricing')"
               :disabled="this.loading"
               class="ml-4 mt-2" color="danger" icon="thumb_down">
      {{ $t('pricing.edit.button.to_correct_pricing') }}
    </vs-button>
    <div
      v-if="hasState('published')"
      class="flex flex-col items-center space-y-4"
    >
      <vs-button
        :disabled="this.loading"
        @click.prevent="createNewVersion()"
        class="mt-2"
        color="warning"
        icon="add"
      >
        {{
          $t("pricing.sheet.button.new.version", {
            sheet: sheet.name,
            company: sheet.company,
          })
        }}
      </vs-button>
      <vs-chip v-if="sheet.state === 'published'" color="success">
        <vs-avatar icon-pack="feather" icon="icon-check" />
        {{ $t("pricing.state.published") }}
      </vs-chip>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <vs-prompt
        :title="$t('comment.action_reject.dialog.title')"
        :accept-text="$t(`comment.${actionInProgress}.dialog.acceptText`)"
        :active.sync="activePromptPostComment"
        :is-valid="!invalid"
        color="danger"
        @accept="postCommentAndRejectSheetOrPricing(actionInProgress)">
        <p class="mb-4">
          {{ $t('comment.action_reject.dialog.text') }}
        </p>
        <ValidationProvider rules="required">
          <vs-textarea v-model="commentPayload.content" :placeholder="$t('comment.input.placeholder')" />
        </ValidationProvider>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'SheetActionButton',
  props: {
    sheet: {
      type: Object,
      required: true
    },
    pricing: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    pricingIsNotPublished () {
      return this.pricing && this.pricing.state !== 'published'
    }
  },
  data () {
    return {
      commentPayload: {
        content: ''
      },
      activePromptPostComment: false,
      actionInProgress: ''
    }
  },
  methods: {
    onClick (action) {
      this.$emit('sheet:action', { id: this.sheet.id, name: this.sheet.name, action })
    },
    async postCommentAndRejectSheetOrPricing (action) {
      try {
        if (action === 'to_correct_pricing') {
          this.commentPayload = {
            ...this.commentPayload,
            pricing: this.sheet.pricing
          }
          await this.$store.dispatch('comment/addComment', { comment: this.commentPayload })
          this.$emit('pricing:correct-pricing', action)
        } else {
          this.commentPayload = {
            ...this.commentPayload,
            sheet: this.sheet['@id']
          }
          await this.$store.dispatch('comment/addComment', { comment: this.commentPayload })
          this.$emit('sheet:action', { id: this.sheet.id, name: this.sheet.name, action })
        }
        this.$vs.notify({
          color: 'success',
          title: this.$t('comment.post.confirm.notify.title'),
          text: this.$t('comment.post.confirm.notify.text')
        })
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
        this.$vs.notify({
          color: 'danger',
          title: this.$t('comment.post.confirm.error.notify.title'),
          text: this.$t('comment.post.confirm.error.notify.text', { error: error.response.data['hydra:description'] }),
          fixed: true
        })
      }
    },
    newVersionPricing () {
      const pricing = {
        type: this.pricing.type['@id'],
        date: this.pricing.date
      }
      this.$emit('post:pricing', pricing)
    },
    promptPostComment (action) {
      this.actionInProgress = action
      this.activePromptPostComment = true
    },
    createNewVersion () {
      this.$emit('sheet:new-version', this.sheet)
    },
    hasAction (action) {
      return this.sheet.action.includes(action)
    },
    hasState (state) {
      return this.sheet.state.includes(state)
    }
  }
}
</script>

<style lang="scss">
.vs-textarea {
  max-height: 400px;
}
</style>
