<template>
  <div class="charge">
    <vs-table class="w-100 my-4" :data="value" sst :mtganotSpacer="true">
      <template slot="thead">
        <vs-th> </vs-th>
        <vs-th class="text-lg">{{$t('pricing.form.charge.value')}}</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
          <vs-td :data="data[indextr].title">
            {{ $t(`${translationPrefix}${data[indextr].title}`) }}
          </vs-td>
          <vs-td :data="data[indextr].value">
            <ValidationProvider v-if="data[indextr].value !== false" :name="$t(`${translationPrefix}${data[indextr].title}`)" rules="required|decimal" v-slot="{ errors }" slim>
              <vx-input-group class="xl:w-2/3 m-1">
                <vs-input v-model="data[indextr].value" :name="`${data[indextr].title}-home`" :color="color" :danger="errors.length > 0" :dangerText="errors.join('<br/>')" :disabled="disabled" />
                <template slot="append">
                  <div class="append-text" :class="`bg-${color}`" :style="{'background-color': color}">
                    <span>{{$t(data[indextr].unit)}}</span>
                  </div>
                </template>
              </vx-input-group>
            </ValidationProvider>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
export default {
  name: 'ChargeInputs',
  props: {
    value: {},
    translationPrefix: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
