import Vue from 'vue'
import axios from '@/axios'
import qs from 'qs'

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    comments: {}
  },
  mutations: {
    ADD_COMMENT (state, payload) {
      if (!state.comments[payload.name]) {
        Vue.set(state.comments, payload.name, [])
      }
      state.comments[payload.name].push(payload.data)
      Vue.set(state.comments, payload.name, state.comments[payload.name])
    },
    UPDATE_COMMENT (state, payload) {
      const commentIndex = state.comments[payload.name].findIndex((c) => c.id === payload.id)
      state.comments[commentIndex] = payload.comment
      Vue.set(state.comments, payload.name, state.comments[payload.name])
    },
    UPDATE_COMMENT_VIEW (state, payload) {
      state.comments[payload.name][payload.index].content = payload.content
      Vue.set(state.comments, payload.name, state.comments[payload.name])
    },
    REMOVE_COMMENT (state, payload) {
      const commentIndex = state.comments[payload.name].findIndex((c) => c.id === payload.id)
      state.comments[payload.name].splice(commentIndex, 1)
      Vue.set(state.comments, payload.name, state.comments[payload.name])
    },
    SET_COMMENTS (state, payload) {
      Vue.set(state.comments, payload.name, payload.data)
    }
  },
  actions: {
    addComment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/comments', payload.comment)
          .then((response) => {
            commit('ADD_COMMENT', {data: response.data, name: payload.name})
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    updateComment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/comments/${payload.id}`, payload.comment)
          .then((response) => {
            commit('UPDATE_COMMENT', {data: response.data, name: payload.name})
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    deleteComment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.delete(`/comments/${payload.id}`)
          .then((response) => {
            commit('REMOVE_COMMENT', {id: payload.id, name: payload.name})
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    fetchComments ({ commit, rootGetters }, payload) {
      if (rootGetters.hasCancelToken('fetchComments')) {
        rootGetters.cancelToken('fetchComments').cancel()
      }
      
      commit('SET_CANCEL_TOKEN', 'fetchComments', {root: true})
      

      return new Promise((resolve, reject) => {
        let url = `/${payload.domain.name}/${payload.domain.id}/comments`

        if (payload.comments) {
          url += `?${qs.stringify(payload.comments)}`
        }
        axios.get(url, {
          cancelToken: rootGetters.cancelToken('fetchComments').token
        }).then((response) => {
          commit('SET_COMMENTS', {data: response.data['hydra:member'], name: payload.domain.name})
          resolve(response)
        })
          .catch((error) => { reject(error) })
      })
    }
  },
  getters: {
    getCommentsByResource: (state) => (resource) => {
      return state.comments[resource]
    }
  }
}
